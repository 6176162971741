import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateExcelFileUpload = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  const handleFileChange = (e) => {
    var fileName = e.target.files[0].name;
    setFileName(fileName);
    console.log("handling file change..." + fileName);
    setFile(e.target.files[0]);
  };

  const uploadExcelFile = async (e) => {
    console.log("uploading excel file...");
    e.preventDefault();
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("strutspos_access_token");

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(apiBaseURL + "/excel_file_uploads", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
      })
      .then((response) => {
        console.log(response.data); // Handle successful upload response

        setSuccessAlert("File uploaded successfully.");
        setErrorAlert("");

        setTimeout(() => {
          navigate("/excel-file-uploads");
        }, 2000);
      })
      .catch((error) => {
        console.error(error); // Handle upload errors
        setErrorAlert("Failed to upload excel file.");
        setSuccessAlert("");
      });

    // try {
    //   const res = await axios({
    //     url: apiBaseURL + "/excel_file_uploads",
    //     headers: {
    //       "x-strutspos-token": accessToken,
    //       "x-strutspos-application": "user",
    //       "Content-Type": "multipart/form-data",
    //     },
    //     method: "POST",
    //     formData,
    //   });

    //   if (res.data.id > 0) {
    //     setSuccessAlert("File uploaded successfully.");
    //     setErrorAlert("");

    //     setTimeout(() => {
    //       navigate("/excel-file-uploads");
    //     }, 2000);
    //   } else {
    //     setErrorAlert("Failed to upload excel file.");
    //     setSuccessAlert("");
    //   }
    // } catch (err) {
    //   console.error("uploadExcelFile err = " + err.message);
    //   setErrorAlert("Failed to upload excel file.");
    //   setSuccessAlert("");
    // }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, [navigate]);

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Upload Excel File</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Upload Excel File</b>
              </p>
              <br />
              <div className="columns">
                <div className="column">
                  <div className="file" style={{ marginBottom: "8px" }}>
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="excel_file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">Choose excel file</span>
                      </span>
                    </label>

                    <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                      {fileName}
                    </div>
                  </div>

                  <br />

                  <div className="field">
                    <button
                      className="button is-link"
                      onClick={uploadExcelFile}
                    >
                      Upload
                    </button>
                  </div>
                </div>
                <div className="column">
                  {/* Email: {user.email}
                  <br />
                  Created At: {createdAt}
                  <br /> */}
                </div>
              </div>
              <br />
              {alert}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateExcelFileUpload;
