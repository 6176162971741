import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Invoice = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [invoice, setInvoice] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // var pdfFile = apiBaseURL + "/invoices/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/invoices/" + id;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoiceData = JSON.parse(JSON.stringify(response.data));
        setInvoice(invoiceData);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Invoice #{invoice.invoice_number}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ borderTop: "1px solid #C8C8C8" }}
            >
              <div className="clearfix"></div>
              <p>
                <b>Invoice #{invoice.invoice_number}</b>
              </p>
              <br />
              Customer Name: {invoice.customer_name}
              <br />
              Customer TIN: {invoice?.customer_tin}
              <br />
              Invoice Number: {invoice.invoice_number}
              <br />
              Invoice Date: {invoice.created_at}
              <br />
              <br />
              Vat: {invoice?.vat}
              <br />
              Grand Total: {invoice?.grand_total}
              <br />
              <br />
              ETIMS Internal Data: {invoice?.etims_internal_data}
              <br />
              ETIMS MRC Number: {invoice?.etims_mrc_number}
              <br />
              ETIMS Receipt Number: {invoice?.etims_receipt_number}
              <br />
              ETIMS Receipt Signature: {invoice?.etims_receipt_signature}
              <br />
              ETIMS Result Date & Time: {invoice?.etims_result_date_time}
              <br />
              ETIMS SDC ID: {invoice?.etims_sdcid}
              <br />
              <br />
              <br />
              <br />
              <div className="columns" style={{ border: "1px solid #C8C8C8" }}>
                <div className="column is-2">
                  {" "}
                  <img
                    alt="TRA Logo"
                    src="/assets/kra_logo.jpg"
                    style={{ marginTop: "30px", maxHeight: "12.50rem" }}
                  />
                </div>
                <div
                  className="column is-10"
                  style={{ borderLeft: "1px solid #C8C8C8" }}
                >
                  <div style={{ marginTop: "-8px" }}>&nbsp;</div>
                  <a
                    href={invoice.verification_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#00d1b2", textTransform: "lowercase" }}
                  >
                    {invoice.verification_url}
                  </a>
                  <br />
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              {/* Enable where pdf files are involved, not needed for API signing. */}
              {/* <iframe
                src={pdfFile}
                title="testPdf"
                height="900px"
                width="100%"
              /> */}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoice;
