import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const ExcelFileUpload = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [apiKey, setExcelFileUpload] = useState({});
  let [modalState, setModalState] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // var pdfFile = apiBaseURL + "/api_keys/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/api_keys/" + id;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let apiKeyData = JSON.parse(JSON.stringify(response.data));
        setExcelFileUpload(apiKeyData);
      })
      .catch((err) =>
        console.error("fetchExcelFileUploads err = " + err.message)
      );
  }, [apiBaseURL, id, navigate]);

  const deleteExcelFileUpload = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("strutspos_access_token");

    try {
      const res = await axios({
        url: apiBaseURL + "/api_keys/" + id,
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
        method: "DELETE",
      });

      if (res.data.id > 0) {
        var welcomeMessage = "API Key deleted successfully.";
        setSuccessAlert(welcomeMessage);
        setErrorAlert("");

        setTimeout(() => {
          navigate("/api-keys");
        }, 2500);
      } else {
        setErrorAlert("Failed to delete API Key.");
        setSuccessAlert("");
      }

      setModalState(false);
    } catch (err) {
      console.error("deleteExcelFileUpload err = " + err.message);
    }
  };

  const Modal = ({ children, closeModal, modalState, title }) => {
    if (!modalState) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={closeModal} />
          </header>
          <section className="modal-card-body">
            <div className="content">{children}</div>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-danger"
              onClick={deleteExcelFileUpload}
            >
              Delete
            </button>
            <button className="button" onClick={closeModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  };

  const toggleModal = (props) => {
    setModalState(!props.modalState);
  };

  const closeModal = () => {
    setModalState(false);
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">ExcelFileUpload</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ borderTop: "1px solid #C8C8C8" }}
            >
              <div className="clearfix"></div>
              <p>
                <b>ExcelFileUpload #{apiKey.api_key}</b>
              </p>
              <br />
              API Key: {apiKey.api_key}
              <br />
              Created At: {apiKey.created_at}
              <br />
              Last Used At: {apiKey.last_used_at}
              <br />
              <hr />
              <button className="button is-danger" onClick={toggleModal}>
                Delete
              </button>
              <Modal
                closeModal={closeModal}
                modalState={modalState}
                title="Confirm API Key Deletion"
              >
                <p>Are you sure you want to delete the API Key?</p>
              </Modal>
              &nbsp;
              <Link
                to={{ pathname: `/api-keys` }}
                className="button is-primary"
              >
                Back to API Keys
              </Link>
              <br />
              <br />
              {alert}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExcelFileUpload;
