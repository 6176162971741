import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import BarChart from "../../components/bar_chart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [invoicesCount, setInvoicesCount] = useState(0);
  const [creditNotesCount, setCreditNotesCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);
  const [storesCount, setStoresCount] = useState(0);
  const [chartData, setChartData] = useState();

  const getDashboardAnalytics = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("strutspos_access_token");

    try {
      const res = await axios({
        url: apiBaseURL + "/analytics",
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
        method: "GET",
      });

      let dashboardAnalytics = JSON.parse(JSON.stringify(res.data));
      setCreditNotesCount(dashboardAnalytics.total_credit_notes_count);
      setInvoicesCount(dashboardAnalytics.total_invoices_count);
      // setSalesCount(dashboardAnalytics.sales_count);
      setCustomersCount(dashboardAnalytics.customers_count);
      // setCategoriesCount(dashboardAnalytics.categories_count);
      setStoresCount(dashboardAnalytics.stores_count);

      let chartSummariesData = dashboardAnalytics.chart_summary;

      setChartData({
        labels: chartSummariesData.map((data) => data.month),
        datasets: [
          {
            label: "Total Sales",
            data: chartSummariesData.map((data) => data.total_sales),
          },
        ],
      });
    } catch (err) {
      console.error("getDashboardAnalytics err = " + err);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    getDashboardAnalytics();
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Dashboard</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Dashboard</b>
              </p>

              <br />

              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">{invoicesCount}</p>
                      <p className="subtitle">Invoices Count</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-horizontal is-parent">
                  <Link to="/credit-notes">
                    <article className="tile is-child box">
                      <p className="title">{creditNotesCount}</p>
                      <p className="subtitle">Credit Notes</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/stores">
                    <article className="tile is-child box">
                      <p className="title has-text-info">{storesCount}</p>
                      <p className="subtitle">Stores Count</p>
                    </article>
                  </Link>
                </div>

                <div className="tile is-parent">
                  <Link to="#">
                    <article className="tile is-child box">
                      <p className="title has-text-warning">{customersCount}</p>
                      <p className="subtitle">Failed Count</p>
                    </article>
                  </Link>
                </div>
              </div>

              <br />
              <br />

              <div className="tile is-ancestor">
                {/* <PieChart chartData={chartData} /> */}

                {/* <Line
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                />
                <Pie
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                /> */}

                {/* {chartRender} */}

                {/* <Bar data={chartData} /> */}

                {chartData && (
                  <BarChart
                    labels={chartData.labels}
                    datasets={chartData.datasets}
                  />
                )}
              </div>

              <br />
              <br />
              <br />

              {/* {{.bar}} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
