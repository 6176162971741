import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faDesktop,
  faMessage,
  faRobot,
  faMemory,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const WebFooter = () => {
  return (
    <>
      <footer class="footer">
        {/* <!-- Copyright symbol of company --> */}
        {/* <div class="section content has-text-centered">
          <p>Copyright © StrutsTechnology</p>
        </div> */}

        {/* <!-- Three columns created using columns class of Bulma --> */}
        <div
          class="columns"
          style={{ marginLeft: "20%", marginRight: "10%", widhth: "80%" }}
        >
          {/* <div class="column" style={{ marginLeft: "5%" }}>
            &nbsp;
          </div> */}
          <div class="column is-4">
            {/* <!-- Heading is left aligned, medium weight --> */}
            <a
              href="https://www.strutstechnology.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/Struts-Technology_-logo.png"
                alt="Struts Technology: Custom Made Software"
                style={{ maxHeight: "4.40rem" }}
              />
              <br />
            </a>

            {/* <!-- footer content --> */}
            <p
              class="bd-footer-link  
                has-text-center"
              style={{ marginLeft: "3%" }}
            >
              Custom Made Software
            </p>
          </div>

          {/* <!-- Column 2 --> */}
          <div class="column is-3" style={{ marginLeft: "5%" }}>
            <h4
              class="bd-footer-title  
                 has-text-weight-semibold  
                 has-text-justify"
            >
              Custom Made Systems
            </h4>

            {/* <!-- Column 2 lists with links --> */}
            <p class="bd-footer-link">
              <FontAwesomeIcon icon={faDesktop} /> &nbsp;
              <Link className="" to="/contact">
                <span class="icon-text">
                  <span style={{ color: "#00d1b2" }}>Software</span>
                </span>
              </Link>
              <br />
              <FontAwesomeIcon icon={faMemory} /> &nbsp;
              <Link className="" to="/kodi-africa">
                <span class="icon-text">
                  <span style={{ color: "#00d1b2" }}>Fiscal</span>
                </span>
              </Link>
              <br />
              <FontAwesomeIcon icon={faRobot} /> &nbsp;
              <Link className="" to="/struts-ai">
                <span class="icon-text">
                  <span style={{ color: "#00d1b2" }}>AI</span>
                </span>
              </Link>
            </p>
          </div>

          {/* <!-- Column 3 --> */}
          <div class="column is-4" style={{ marginLeft: "5%" }}>
            <h4
              class="bd-footer-title 
                 has-text-weight-semibold 
                 has-text-justify"
            >
              Contact us
            </h4>

            {/* <!-- Column 3 lists with links --> */}
            <p class="bd-footer-link">
              <FontAwesomeIcon icon={faAddressBook} /> &nbsp;
              <Link className="" to="/contact">
                <span class="icon-text">
                  <span style={{ color: "#00d1b2" }}>Contact us</span>
                </span>
              </Link>
              <br />
              <FontAwesomeIcon icon={faMessage} /> &nbsp;
              <Link className="" to="/struts-ai">
                <span class="icon-text">
                  <span style={{ color: "#00d1b2" }}>Chat with us</span>
                </span>
              </Link>
              <br />
              <FontAwesomeIcon icon={faTwitter} /> &nbsp;
              <a
                href="https://twitter.com/StrutsTech"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                @StrutsTech
              </a>
              &nbsp;
              <a
                href="https://twitter.com/StrutsAI"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                @StrutsAI
              </a>
              &nbsp;
              <a
                href="https://twitter.com/Kodi_Africa"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                @Kodi_Africa
              </a>
              <br />
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default WebFooter;
