import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Category = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [category, setCategory] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // var pdfFile = apiBaseURL + "/categories/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/categories/" + id;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let categoryData = JSON.parse(JSON.stringify(response.data));
        setCategory(categoryData);
      })
      .catch((err) => console.error("fetchCategorys err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Category</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ borderTop: "1px solid #C8C8C8" }}
            >
              <div className="clearfix"></div>
              <p>
                <b>Category: {category.name}</b>
              </p>
              <br />
              Name: {category.name}
              <br />
              Description: {category.description}
              <br />
              Created At: {category.created_at}
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
