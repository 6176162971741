import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";

const ExcelFileUploads = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [excelFileUploads, setExcelFileUploads] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setCurrentPage(1);
    setPer(8);

    const currentDate = new Date();
    const previousDay = new Date(currentDate);
    previousDay.setDate(currentDate.getDate() - 1);

    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    setStartDate(previousDay);
    setEndDate(nextDay);

    var url = apiBaseURL + "/excel_file_uploads?per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let excelFileUploadsData = JSON.parse(JSON.stringify(response.data));
        let excelFileUploadsCount = excelFileUploadsData.pagination.count;
        setCount(excelFileUploadsCount);
        setExcelFileUploads(excelFileUploadsData.excel_file_uploads);

        let numPages = Math.ceil(excelFileUploadsCount / per);
        setPageCount(numPages);
      })
      .catch((err) =>
        console.error("fetchExcelFileUploads err = " + err.message)
      );
  }, [apiBaseURL, per, navigate]);

  const searchExcelFileUploads = (searchTermText) => {
    var accessToken = localStorage.getItem("strutspos_access_token");
    var url = apiBaseURL + "/excel_file_uploads?search=" + searchTermText;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let excelFileUploadsData = JSON.parse(JSON.stringify(response.data));
        let excelFileUploadsCount = excelFileUploadsData.pagination.count;
        setCount(excelFileUploadsCount);
        setExcelFileUploads(excelFileUploadsData.excel_file_uploads);

        let numPages = Math.ceil(excelFileUploadsCount / per);
        setPageCount(numPages);
      })
      .catch((err) =>
        console.error("searchExcelFileUploads err = " + err.message)
      );
  };

  const handlePageClick = (data) => {
    filterExcelFileUploads(data);
  };

  const filterExcelFileUploads = (data) => {
    // var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("strutspos_access_token");
    setCurrentPage(data);

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/excel_file_uploads?page=" + data + "&per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let excelFileUploadsData = JSON.parse(JSON.stringify(response.data));
        let excelFileUploadsCount = excelFileUploadsData.pagination.count;
        setCount(excelFileUploadsCount);
        setExcelFileUploads(excelFileUploadsData.excel_file_uploads);

        let numPages = Math.ceil(excelFileUploadsCount / per);
        setPageCount(numPages);
      })
      .catch((err) =>
        console.error("filter ExcelFileUploads err = " + err.message)
      );
  };

  const dateFilterExcelFileUploads = (dateStart, dateEnd) => {
    var accessToken = localStorage.getItem("strutspos_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url =
      apiBaseURL +
      "/excel_file_uploads?page=1&per=" +
      per +
      "&start_date=" +
      dateStart +
      "&end_date=" +
      dateEnd;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let excelFileUploadsData = JSON.parse(JSON.stringify(response.data));
        let excelFileUploadsCount = excelFileUploadsData.pagination.count;
        setCount(excelFileUploadsCount);
        setExcelFileUploads(excelFileUploadsData.excel_file_uploads);

        let numPages = Math.ceil(excelFileUploadsCount / per);
        setPageCount(numPages);
      })
      .catch((err) =>
        console.error("filter ExcelFileUploads err = " + err.message)
      );
  };

  const updateStartDate = (date) => {
    setStartDate(date);

    const formattedStartDate = date.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    // Filter excelFileUploads based on start and end dates
    dateFilterExcelFileUploads(formattedStartDate, formattedEndDate);
  };

  const updateEndDate = (date) => {
    setEndDate(date);

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = date.toISOString().split("T")[0];

    // Filter excelFileUploads based on start and end dates
    dateFilterExcelFileUploads(formattedStartDate, formattedEndDate);
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Excel File Uploads</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div className="columns">
                <div className="column">
                  <b>Uploads ({count})</b>
                  &nbsp;&nbsp;
                  {/* <Link
                    to="/ai-trainings-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link> */}
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">From:</label>
                    </div>
                    <div className="field-body" style={{ width: "60%" }}>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => updateStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ width: "100%" }}
                    >
                      <label className="label">To:</label>
                    </div>
                    <div
                      className="field-body is-half-desktop"
                      style={{ width: "60%" }}
                    >
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => updateEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div
                    className="bd-notification2 is-primary is-pulled-right"
                    data-tooltip="Export Excel"
                  >
                    <Link
                      to={{ pathname: `/excel-file-uploads/upload` }}
                      className="button is-link"
                    >
                      Upload
                    </Link>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchExcelFileUploads(e.target.value)}
                />
              </p>
              <br />
              <table className="table is-striped is-hoverable" width="100%">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Records Count</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>File Name</th>
                    <th>Records Count</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {excelFileUploads.map((item) => (
                    <tr key={item.id}>
                      <th>{item.file_name}</th>
                      <td>{item.records_count}</td>
                      <td>{item.created_at.substring(0, 10)}</td>
                      <th>
                        <Link
                          to={{ pathname: `/excel-file-uploads/${item.id}` }}
                          className="button is-primary"
                        >
                          View
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div
                className="container is-centered"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <Pagination
                    pages={pageCount}
                    currentPage={currentPage}
                    onChange={(page) => handlePageClick(page)}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExcelFileUploads;
