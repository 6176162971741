import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ZReport = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [zreport, setZReport] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  var pdfFile = apiBaseURL + "/zreports/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/zreports/" + id;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let zreportData = JSON.parse(JSON.stringify(response.data));
        setZReport(zreportData);
      })
      .catch((err) => console.error("fetchZReports err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">ZReport #{zreport.id}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>ZReport #{zreport.id}</b>
              </p>
              <br />
              File name: {zreport.file_name}
              <br />
              Net Amount: {zreport.net_amount}
              <br />
              Vat: {zreport.vat_amount}
              <br />
              Total Amount: {zreport.total_amount}
              <br />
              ZReport Date: {zreport.z_report_date}
              <br />
              {/* Verification URL:{" "}
              <a
                href={zreport.id}
                target="_blank"
                rel="noreferrer"
              >
                {zreport.verification_url}
              </a>
              <br /> */}
              <br />
              {/* Enable where pdf files are involved, not needed for API signing. */}
              <iframe
                src={pdfFile}
                title="testPdf"
                height="900px"
                width="100%"
              />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZReport;
