import React, { useEffect, useRef } from "react";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import config from "../configs/chatbotConfig";
import Chatbot from "react-chatbot-kit";
import AIMessageParser from "../chatbot/AIMessageParser";
import AIActionProvider from "../chatbot/AIActionProvider.js";
import { createClientMessage } from "react-chatbot-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const StrutsAI = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader(
    "Struts AI",
    "Unleash the Power of AI to Revolutionize Your Business."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    createClientMessage("Hello there.");
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          <section className="section is-normal">
            <h1 className="title">Struts AI</h1>
            <h2 className="subtitle">
              Unleash the Power of AI to Revolutionize Your Business.
            </h2>
            <div className="columns">
              <div className="column is-three-fifths">
                <br />
                <h3 className="title is-4">
                  Elevate Your Business with Automated Chat Solutions!{" "}
                </h3>
                <b>Unlock the Power of Chatbots:</b> Train Your Personal
                Assistant to Engage Customers, Gather Information, and Follow Up
                Seamlessly.
                <br /> <br />
                <b>Say Hello to Effortless Customer Support:</b> Harness the
                Potential of AI-Powered Chatbots to Handle Queries and Boost
                Your Sales!
                <br /> <br />
                <b>Customize Your Conversations:</b> Craft Tailored Interactions
                and Capture Customer Details for Future Success. Don't Miss Out
                on Opportunities - Automate and Prosper!
                <br />
                <br />
                <div>
                  <div className="buttons">
                    <a
                      href="https://ai.strutstechnology.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button is-primary"
                    >
                      <strong>Get Started</strong>
                    </a>
                  </div>
                </div>
                <br />
                <br />
                <FontAwesomeIcon icon={faTwitter} /> &nbsp;
                <a
                  href="https://twitter.com/StrutsAI"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#00d1b2" }}
                >
                  @StrutsAI
                </a>
              </div>
              <div className="column is-1">&nbsp;</div>
              <div className="column">
                <div
                  style={{
                    border: "2px solid #00d1b2",
                    borderRadius: "7.5px",
                    width: "max-content",
                    marginTop: "-65px",
                  }}
                >
                  <Chatbot
                    config={config}
                    messageParser={AIMessageParser}
                    actionProvider={AIActionProvider}
                  />
                </div>
              </div>
            </div> 
            {/* <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p> */}
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default StrutsAI;
