import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Invoice Processing",
    },
  },
};

function LineChart(props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Total Processed Invoices",
        data: props.datasets[0].data,
        backgroundColor: "#3e8ed0",
      },
      {
        label: "Signed Invoices",
        data: props.datasets[0].data,
        backgroundColor: "#00d1b2",
      },
    ],
  };

  return (
    <>
      <Line data={data} options={options} /> <br />
      <br />
    </>
  );
}

export default LineChart;
