import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Store = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [store, setStore] = useState({});

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  // var pdfFile = apiBaseURL + "/stores/" + id + "/pdf";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/stores/" + id;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let storeData = JSON.parse(JSON.stringify(response.data));
        setStore(storeData);
      })
      .catch((err) => console.error("fetchStores err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Store: {store.name}</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ borderTop: "1px solid #C8C8C8" }}
            >
              <div className="clearfix"></div>
              <p>
                <b>Store #{store.name}</b>
              </p>
              <br />
              Name: {store.name}
              <br />
              Phone Number: {store.phone_number}
              <br />
              Email: {store.email}
              <br />
              Location: {store.location}
              <br />
              Created At: {store.created_at}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Store;
