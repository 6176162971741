import React, { useEffect, useRef } from "react";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const KodiAfrica = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader(
    "Kodi Africa",
    "Automated Fiscal Documents Processing for Tax Compliance."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          {/* <h1 className="title">Struts Technology</h1>
          <p className="subtitle">
            Electronic Signature Device signing, reporting and analytics tool.
          </p> */}

          <section className="section is-normal">
            <h1 className="title">Automated Fiscal Documents Processing</h1>
            <h2 className="subtitle">
              We automate invoice and receipt documents processing and post them
              to revenue authority servers.
            </h2>
            <br />
            <a
              href="https://kodi.africa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/kodi-africa-dashboard.png"
                alt="Kodi.Africa Dashboard"
              />
            </a>
            <br />
            <br />
            <br />
            <br />
            <h2 className="subtitle">
              We have integrations in place for Kenya and Tanzania, integrating
              with Kenya Revenue Authority (KRA) and Tanzania Revenue Authority
              respectively.
            </h2>
            <br />
            <div>
              <div className="buttons">
                <a
                  href="https://kodi.africa"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-primary"
                >
                  <strong>Get Started</strong>
                </a>
              </div>
            </div>
            <br />
            <br />
            <FontAwesomeIcon icon={faTwitter} /> &nbsp;
            <a
              href="https://twitter.com/Kodi_Africa"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              @Kodi_Africa
            </a>
            <br />
            {/* <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p> */}
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default KodiAfrica;
