import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";

const Home = () => {
  const navigate = useNavigate();

  let heroHeader = useRef();

  heroHeader.current = HeroHeader("Optimus", "ETIMS APIs, POS, Business APIs");

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (accessToken) {
      navigate("/dashboard");
    }

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      {/* <WebNavbar /> */}
      {heroHeader.current}
      <section className="section">
        <div className="container">
          <section className="section is-normal">
            <h1 className="title">Optimus</h1>
            <p className="subtitle">ETIMS APIs, POS, Business APIs</p>

            <br />

            <div className="buttons">
              <Link to="/login" className="button is-primary" href="/login">
                <strong>Log in</strong>
              </Link>
            </div>
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default Home;
