import React from "react";
import { styles } from "../styles";
import ChatBotForm from "./ChatBotForm";

const SupoprtWindow = (props) => {
  return (
    <div
      className="transition-5"
      style={{
        ...styles.supportWindow,
        ...{ opacity: props.visible ? "1" : "0" },
        ...{ display: props.visible ? "block" : "none" },
      }}
    >
      <ChatBotForm style={{ width: "100%" }} />
    </div>
  );
};

export default SupoprtWindow;
