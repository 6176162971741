import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";

const Categories = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setCurrentPage(1);
    setPer(8);

    const currentDate = new Date();
    const previousDay = new Date(currentDate);
    previousDay.setDate(currentDate.getDate() - 1);

    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    setStartDate(previousDay);
    setEndDate(nextDay);

    var url = apiBaseURL + "/categories?per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let categoriesData = JSON.parse(JSON.stringify(response.data));
        let categoriesCount = categoriesData.pagination.count;
        setCount(categoriesCount);
        setCategories(categoriesData.categories);

        let numPages = Math.ceil(categoriesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchCategories err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchCategories = (searchTermText) => {
    var accessToken = localStorage.getItem("strutspos_access_token");
    var url = apiBaseURL + "/categories?search=" + searchTermText;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let categoriesData = JSON.parse(JSON.stringify(response.data));
        let categoriesCount = categoriesData.pagination.count;
        setCount(categoriesCount);
        setCategories(categoriesData.categories);

        let numPages = Math.ceil(categoriesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchCategories err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterCategories(data);
  };

  const filterCategories = (data) => {
    var selectedPage = data.selected + 1;
    var accessToken = localStorage.getItem("strutspos_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/categories?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let categoriesData = JSON.parse(JSON.stringify(response.data));
        let categoriesCount = categoriesData.pagination.count;
        setCount(categoriesCount);
        setCategories(categoriesData.categories);

        let numPages = Math.ceil(categoriesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Categories err = " + err.message));
  };

  const dateFilterCategories = (dateStart, dateEnd) => {
    var accessToken = localStorage.getItem("strutspos_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url =
      apiBaseURL +
      "/categories?page=1&per=" +
      per +
      "&start_date=" +
      dateStart +
      "&end_date=" +
      dateEnd;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let categoriesData = JSON.parse(JSON.stringify(response.data));
        let categoriesCount = categoriesData.pagination.count;
        setCount(categoriesCount);
        setCategories(categoriesData.categories);

        let numPages = Math.ceil(categoriesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Categories err = " + err.message));
  };

  const downloadExcelCategories = async () => {
    try {
      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      var accessToken = localStorage.getItem("strutspos_access_token");
      var apiURL =
        apiBaseURL +
        "/categories/download?page=1&per=" +
        1000 +
        "&start_date=" +
        formattedStartDate +
        "&end_date=" +
        formattedEndDate;

      const response = await axios.get(apiURL, {
        responseType: "blob",
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
      });

      const blob = new Blob([response.data]);

      // Get the filename from the Content-Disposition header
      console.log(response.headers);
      const contentDisposition = response.headers["content-disposition"];
      console.log(contentDisposition);

      const parts = contentDisposition.split("=");
      // Extract the second parameter as the filename
      const filename = parts[1].trim();
      console.log(filename);

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const updateStartDate = (date) => {
    setStartDate(date);

    const formattedStartDate = date.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    // Filter categories based on start and end dates
    dateFilterCategories(formattedStartDate, formattedEndDate);
  };

  const updateEndDate = (date) => {
    setEndDate(date);

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = date.toISOString().split("T")[0];

    // Filter categories based on start and end dates
    dateFilterCategories(formattedStartDate, formattedEndDate);
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Categories</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div className="columns">
                <div className="column">
                  <b>Categories ({count})</b>
                  &nbsp;&nbsp;
                  {/* <Link
                    to="/ai-trainings-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link> */}
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">From:</label>
                    </div>
                    <div className="field-body" style={{ width: "60%" }}>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => updateStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ width: "100%" }}
                    >
                      <label className="label">To:</label>
                    </div>
                    <div
                      className="field-body is-half-desktop"
                      style={{ width: "60%" }}
                    >
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => updateEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div
                    className="bd-notification2 is-primary is-pulled-right"
                    data-tooltip="Export Excel"
                  >
                    <button
                      className="button is-link"
                      onClick={() => downloadExcelCategories()}
                    >
                      Export Excel
                    </button>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchCategories(e.target.value)}
                />
              </p>
              <br />
              <table className="table is-striped is-hoverable" width="100%">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>View</th>
                  </tr>
                </tfoot>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category.id}>
                      <th>{category.name}</th>
                      <td>{category.description}</td>
                      <td>{category.created_at}</td>
                      <th>
                        <Link
                          to={{ pathname: `/categories/${category.id}` }}
                          className="button is-primary"
                        >
                          View
                        </Link>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div
                className="container is-centered"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <Pagination
                    pages={pageCount}
                    currentPage={currentPage}
                    onChange={(page) => handlePageClick(page)}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Categories;
