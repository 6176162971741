import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AdminNavbar from "../components/admin_navbar";
import AdminSideMenu from "../components/admin_side_menu";
import XeroConfiguration from "../components/xero_configuration";
import { useNavigate } from "react-router-dom";

const XeroCallback = () => {
  const navigate = useNavigate();
  let apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let code = params.get("code");
  let scope = params.get("scope");
  let state = params.get("state");

  console.log("code: " + code);
  console.log("scope: " + scope);
  console.log("state: " + state);

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, [apiBaseURL, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Configurations</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <XeroConfiguration />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default XeroCallback;
