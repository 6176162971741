import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const HeroHeader = (title, subtitle) => {
  // Get the current location object
  const location = useLocation();
  // Access the pathname (URL path) property from the location object
  const currentPath = location.pathname;

  const isHomeMenu = currentPath === "/" || currentPath === "/contact2";

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");

    if (accessToken) {
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <section
          className="hero is-primary is-medium header-image"
          style={{ backgroundImage: 'url("./assets/bg-3.png")' }}
        >
          {/* <!-- Hero header: will stick at the top --> */}
          <nav
            className="navbar is-light"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                  <img
                    src="/assets/logo.png"
                    alt="Optimus"
                    style={{ maxHeight: "2.50rem" }}
                  />
                  &nbsp; <b>Optimus</b>
                </Link>

                <Link
                  to="/"
                  role="button"
                  className="navbar-burger"
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarBasicExample"
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </Link>
              </div>

              <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-end">
                  <div className="navbar-item">
                    <div className="buttons">
                      <Link
                        to="/login"
                        className="button is-primary"
                        href="/login"
                      >
                        <strong>Log in</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          {/* <!-- Hero content: will be in the middle --> */}
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">{title}</h1>
              <h2 className="subtitle">{subtitle}</h2>
            </div>
          </div>
          {/* <!-- Hero footer: will stick at the bottom --> */}
          <div className="hero-foot">
            <nav className="tabs is-boxed is-fullwidth">
              <div className="container">
                <ul>
                  <li className={`${isHomeMenu ? "is-active" : ""}`}>
                    <Link to="/" className="navbar-item">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`${
                      currentPath === "/struts-ai" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/struts-ai">Struts AI</Link>
                  </li>
                  {/* <li
                    className={`${
                      currentPath === "/autofisc" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/autofisc">Autofisc </Link>
                  </li> */}
                  <li
                    className={`${
                      currentPath === "/kodi-africa" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/kodi-africa">Kodi Africa </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroHeader;
