import React from "react";
import { Link } from "react-router-dom";

const AdminSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Dashboard</p>
        <ul className="menu-list">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>
        <p className="menu-label">Invoices</p>
        <ul className="menu-list">
          {/* <li>
            <Link to="/excel-file-uploads">Excel Uploads</Link>
          </li> */}
          <li>
            <Link to="/invoices">Invoices</Link>
          </li>
          <li>
            <Link to="/credit-notes">Credit Notes</Link>
          </li>
        </ul>
        {/* <p className="menu-label">Items</p>
        <ul className="menu-list">
          <li>
            <Link to="/categories">Categories</Link>
          </li>
          <li>
            <Link to="/items">Items</Link>
          </li>
        </ul> */}
        <p className="menu-label">Stores</p>
        <ul className="menu-list">
          <li>
            <Link to="/stores">Manage Stores</Link>
          </li>
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/analytics">Signing Analytics</Link>
          </li>
          {/* <li>
            <Link to="/zreports">Z Reports</Link>
          </li> */}
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          {/* <li>
            <Link to="/api-keys">API Keys</Link>
          </li> */}
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSideMenu;
