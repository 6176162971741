import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";

const Invoices = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoices, setInvoices] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setCurrentPage(1);
    setPer(8);

    const currentDate = new Date();
    const previousDay = new Date(currentDate);
    previousDay.setDate(currentDate.getDate() - 1);

    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    setStartDate(previousDay);
    setEndDate(nextDay);

    var url = apiBaseURL + "/invoices?per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchInvoices = (searchTermText) => {
    var accessToken = localStorage.getItem("strutspos_access_token");
    var url = apiBaseURL + "/invoices?search=" + searchTermText;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchInvoices err = " + err.message));
  };

  const handlePageClick = (data) => {
    filterInvoices(data);
  };

  const filterInvoices = (data) => {
    var selectedPage = data;
    setCurrentPage(selectedPage);
    var accessToken = localStorage.getItem("strutspos_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/invoices?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Invoices err = " + err.message));
  };

  const dateFilterInvoices = (dateStart, dateEnd) => {
    var accessToken = localStorage.getItem("strutspos_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url =
      apiBaseURL +
      "/invoices?page=1&per=" +
      per +
      "&start_date=" +
      dateStart +
      "&end_date=" +
      dateEnd;

    const config = {
      headers: {
        "x-strutspos-token": accessToken,
        "x-strutspos-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let invoicesData = JSON.parse(JSON.stringify(response.data));
        let invoicesCount = invoicesData.pagination.count;
        setCount(invoicesCount);
        setInvoices(invoicesData.invoices);

        let numPages = Math.ceil(invoicesCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Invoices err = " + err.message));
  };

  const downloadExcelInvoices = async () => {
    try {
      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      var accessToken = localStorage.getItem("strutspos_access_token");
      var apiURL =
        apiBaseURL +
        "/invoices/download?page=1&per=" +
        1000 +
        "&start_date=" +
        formattedStartDate +
        "&end_date=" +
        formattedEndDate;

      const response = await axios.get(apiURL, {
        responseType: "blob",
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
      });

      const blob = new Blob([response.data]);

      // Get the filename from the Content-Disposition header
      console.log(response.headers);
      const contentDisposition = response.headers["content-disposition"];
      console.log(contentDisposition);

      const parts = contentDisposition.split("=");
      // Extract the second parameter as the filename
      const filename = parts[1].trim();
      console.log(filename);

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const updateStartDate = (date) => {
    setStartDate(date);

    const formattedStartDate = date.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    // Filter invoices based on start and end dates
    dateFilterInvoices(formattedStartDate, formattedEndDate);
  };

  const updateEndDate = (date) => {
    setEndDate(date);

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = date.toISOString().split("T")[0];

    // Filter invoices based on start and end dates
    dateFilterInvoices(formattedStartDate, formattedEndDate);
  };

  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Invoices</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div className="columns">
                <div className="column">
                  <b>Invoices ({count})</b>
                  &nbsp;&nbsp;
                  {/* <Link
                    to="/ai-trainings-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link> */}
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">From:</label>
                    </div>
                    <div className="field-body" style={{ width: "60%" }}>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => updateStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-4" style={{}}>
                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ width: "100%" }}
                    >
                      <label className="label">To:</label>
                    </div>
                    <div
                      className="field-body is-half-desktop"
                      style={{ width: "60%" }}
                    >
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => updateEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div
                    className="bd-notification2 is-primary is-pulled-right"
                    data-tooltip="Export Excel"
                  >
                    <button
                      className="button is-link"
                      onClick={() => downloadExcelInvoices()}
                    >
                      Export Excel
                    </button>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchInvoices(e.target.value)}
                />
              </p>
              <br />
              <div className="horizontal-scroll" onWheel={handleScroll}>
                <table
                  className="table is-striped is-hoverable scrollable-item"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>VoucherID#</th>
                      <th>Customer Name</th>
                      <th>Customer PIN</th>
                      <th>ETIMS Signature</th>
                      <th>ETIMS Internal Data</th>
                      <th>VAT</th>
                      <th>Grand Total</th>
                      <th>Created At</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>VoucherID#</th>
                      <th>Customer Name</th>
                      <th>Customer PIN</th>
                      <th>ETIMS Signature</th>
                      <th>ETIMS Internal Data</th>
                      <th>VAT</th>
                      <th>Grand Total</th>
                      <th>Created At</th>
                      <th>View</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr key={invoice.id}>
                        <th>{invoice.invoice_number}</th>
                        <td>{invoice.customer_name}</td>
                        <td>{invoice.customer_tin}</td>
                        <td>{invoice?.etims_receipt_signature}</td>
                        <td>{invoice?.etims_internal_data}</td>
                        <td>{invoice?.vat}</td>
                        <td>{invoice.grand_total}</td>
                        <td>{invoice.created_at.substring(0, 10)}</td>
                        <th>
                          <Link
                            to={{ pathname: `/invoices/${invoice.id}` }}
                            className="button is-primary"
                          >
                            View
                          </Link>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="container is-centered"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <Pagination
                    pages={pageCount}
                    currentPage={currentPage}
                    onChange={(page) => handlePageClick(page)}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoices;
