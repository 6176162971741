import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateAPIKey = () => {
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  const createAPIKey = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("strutspos_access_token");

    try {
      const res = await axios({
        url: apiBaseURL + "/api_keys",
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
        method: "POST",
        data: {
          description: description,
        },
      });

      if (res.data.id > 0) {
        var welcomeMessage = "API Key created successfully.";
        setSuccessAlert(welcomeMessage);
        setErrorAlert("");

        setTimeout(() => {
          navigate("/api-keys");
        }, 2000);
      } else {
        setErrorAlert("Failed to create API Key.");
        setSuccessAlert("");
      }
    } catch (err) {
      console.error("createAPIKey err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
  }, [navigate]);

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Create API Key</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Create API Key</b>
              </p>
              <br />
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Description</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <button className="button is-link" onClick={createAPIKey}>
                      Save
                    </button>
                  </div>
                </div>
                <div className="column">
                  {/* Email: {user.email}
                  <br />
                  Created At: {createdAt}
                  <br /> */}
                </div>
              </div>
              <br />
              {alert}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAPIKey;
