import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Invoice Processing",
    },
  },
};

function PieChart(props) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Total Processed Invoices",
        data: props.datasets[0].data,
        backgroundColor: "#3e8ed0",
      },
      {
        label: "Signed Invoices",
        data: props.datasets[0].data,
        backgroundColor: "#00d1b2",
      },
    ],
  };

  return (
    <>
      <Pie data={data} options={options} /> <br />
      <br />
    </>
  );
}

export default PieChart;
