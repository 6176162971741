import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import LineChart from "../../components/line_chart";
import PieChart from "../../components/pie_chart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [salesCount, setSalesCount] = useState(0);
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [chartData, setChartData] = useState();

  const getDashboardAnalytics = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("strutspos_access_token");

    try {
      const res = await axios({
        url: apiBaseURL + "/analytics/dashboard",
        headers: {
          "x-strutspos-token": accessToken,
          "x-strutspos-application": "user",
        },
        method: "GET",
      });

      let dashboardAnalytics = JSON.parse(JSON.stringify(res.data));
      setSalesCount(dashboardAnalytics.sales_count);
      setCustomersCount(dashboardAnalytics.customers_count);
      setCategoriesCount(dashboardAnalytics.categories_count);
      setItemsCount(dashboardAnalytics.items_count);

      let chartSummariesData = dashboardAnalytics.chart_summary;

      setChartData({
        labels: chartSummariesData.map((data) => data.month),
        datasets: [
          {
            label: "Total Sales",
            data: chartSummariesData.map((data) => data.total_sales),
          },
        ],
      });
    } catch (err) {
      console.error("getDashboardAnalytics err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("strutspos_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    getDashboardAnalytics();
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Analytics</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <p>
                <b>Analytics</b>
              </p>

              <br />

              <div className="tile is-ancestor">
                <div className="tile is-horizontal is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title">{salesCount}</p>
                      <p className="subtitle">Sales Count</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-info">{itemsCount}</p>
                      <p className="subtitle">Items Count</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/categories">
                    <article className="tile is-child box">
                      <p className="title has-text-primary">
                        {categoriesCount}
                      </p>
                      <p className="subtitle">Categories Count</p>
                    </article>
                  </Link>
                </div>
                <div className="tile is-parent">
                  <Link to="/invoices">
                    <article className="tile is-child box">
                      <p className="title has-text-warning">{customersCount}</p>
                      <p className="subtitle">Customers Count</p>
                    </article>
                  </Link>
                </div>
              </div>

              <br />
              <br />

              {chartData && (
                <LineChart
                  labels={chartData.labels}
                  datasets={chartData.datasets}
                />
              )}

              <br />
              <br />

              {chartData && (
                <PieChart
                  labels={chartData.labels}
                  datasets={chartData.datasets}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
