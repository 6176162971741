import React from "react";
import axios from "axios";

const AIActionProvider = ({ createChatBotMessage, setState, children }) => {
  async function chatAI(message) {
    var apiBaseURL = process.env.REACT_APP_AI_API_BASE_URL;

    const res = await axios({
      url: `${apiBaseURL}/chatbot`,
      method: "POST",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-strutsai-application": "user",
        "x-client-identifier": "web",
        "struts-api-key": process.env.REACT_APP_STRUTS_API_KEY,
      },
      data: {
        client: "struts",
        input_text: message,
      },
    })
      .then((response) => {
        if (response.data) {
          var response_message = response.data.response;
          return response_message;
        } else {
          console.log("Error processing AI Response");
          return "Unable to process your request, please try again.";
        }
      })
      .catch((error) => {
        console.log("Error processing AI Response error: " + error);
        return "We are unable to process your request, please try again.";
      });

    return await res;
  }

  async function handleHello(message) {
    const responseMessage = await chatAI(message);
    const botMessage = createChatBotMessage(responseMessage);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
          },
        });
      })}
    </div>
  );
};

export default AIActionProvider;
