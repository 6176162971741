import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/sign_up";

// Admin dashboard routes
import Analytics from "./pages/admin/analytics";
import APIKeys from "./pages/admin/api-keys";
import APIKey from "./pages/admin/api-keys/id";
import APIKeyCreate from "./pages/admin/api-keys/create-api-key";
import Categories from "./pages/admin/item-categories";
import Category from "./pages/admin/item-categories/id";
import Configurations from "./pages/admin/configurations";
import Contact from "./pages/contact";
import CreditNotes from "./pages/admin/credit-notes";
import CreditNote from "./pages/admin/credit-notes/id";
import Dashboard from "./pages/admin/dashboard";
import ExcelFileUploads from "./pages/admin/excel-file-uploads";
import ExcelFileUpload from "./pages/admin/excel-file-uploads/id";
import UploadExcelFile from "./pages/admin/excel-file-uploads/upload-excel-file";
import Invoices from "./pages/admin/invoices";
import InvoicesEmailFail from "./pages/admin/invoices/email-fail";
import Invoice from "./pages/admin/invoices/id";
import Items from "./pages/admin/items";
import Item from "./pages/admin/items/id";
import KodiAfrica from "./pages/kodi-africa";
import Profile from "./pages/admin/profile";
import Stores from "./pages/admin/stores";
import Store from "./pages/admin/stores/id";
import StrutsAI from "./pages/struts-ai";
import SupportEngine from "./SupportEngine";
import VFDConfiguration from "./pages/admin/configurations/vfd_configuration";
import XeroCallback from "./pages/xero-callback";
import ZReports from "./pages/admin/zreports";
import ZReport from "./pages/admin/zreports/id";

import "./App.css";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="/api-keys" element={<APIKeys />} />
        <Route path="/api-keys/:id" element={<APIKey />} />
        <Route path="/api-keys/create" element={<APIKeyCreate />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/:id" element={<Category />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/credit-notes" element={<CreditNotes />} />
        <Route path="/credit-notes/:id" element={<CreditNote />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/excel-file-uploads" element={<ExcelFileUploads />} />
        <Route path="/excel-file-uploads/:id" element={<ExcelFileUpload />} />
        <Route
          path="/excel-file-uploads/upload"
          element={<UploadExcelFile />}
        />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoices-email-fail" element={<InvoicesEmailFail />} />
        <Route path="/invoices/:id" element={<Invoice />} />
        <Route path="/items" element={<Items />} />
        <Route path="/items/:id" element={<Item />} />
        <Route path="/kodi-africa" element={<KodiAfrica />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/stores/:id" element={<Store />} />
        <Route path="/struts-ai" element={<StrutsAI />} />
        <Route path="/configurations" element={<Configurations />} />
        <Route path="/vfd-configuration" element={<VFDConfiguration />} />
        <Route path="/xero-callback" element={<XeroCallback />} />
        <Route path="/zreports" element={<ZReports />} />
        <Route path="/zreports/:id" element={<ZReport />} />
      </Routes>

      <SupportEngine />
    </>
  );
};

export default App;
